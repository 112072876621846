import packageJson from '../../package.json';
import Endpoint from './endpoints';

// Environments
export const IS_PRODUCTION = false;//process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';
export const IS_PREVIEW = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';

// Application
export const APP_AUTHOR  = 'Refuge Studios';
export const APP_NAME    = 'ChitChat';
export const APP_DOMAIN  = 'refugestudios.com.au';

export const DESCRIPTION = ``;
export const APP_VERSION = packageJson.version;
export const BRAND_COLOR = '#FB3A5D';

export const UPLOAD_URL = 'http://lpm.dxc.mybluehost.me/';


export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51PlALcHeIDIy0wJ7cfTYejdhMACTwSJ37X1jf1mLUJ55oinagoNypqJXdFI4hOiVmyW0nPJRpkUAWwUxt7xLbosb00bhI3ksPJ';


export const API_URL = Endpoint.Mainnet;// IS_PRODUCTION
//  ?  Endpoint.Mainnet
//  : 'http://localhost:4784';

export const API_WS_URL = 'wss://api.refugestudios.com.au';//IS_PRODUCTION //'ws://api.refugestudios.com.au';
//  ? Endpoint.Mainnet
//  : 'ws://localhost:4784';

export const WEB_URL = 'https://refugestudios.com.au';//IS_PRODUCTION
// ? 'https://refugestudios.com.au'
// : 'http://localhost:4783/';

//export const API_URL = is_development ? 'http://localhost:3002/api' : 'https://www.refugestudios.com.au:3002/api';
//export const API_WS_URL = is_development ? 'ws://localhost:3003' : 'https://www.refugestudios.com.au:3003';

export const TENOR_API_KEY = 'AIzaSyBKyyfIb2TzKPua0zMMIbV_--6Dyv09Jeg';
